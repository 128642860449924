import { QueryState, queryState as qs } from '$scripts/query-state';
import { getAuthServicesEndpoint } from './index';

import { PATHNAMES } from './constants';

export async function getQueryStateId(
  client_id: string,
  queryState: QueryState
): Promise<{ id: string; queryState: string }> {
  const authServicesEndpoint = await getAuthServicesEndpoint();

  const formattedQueryString = new URLSearchParams(qs.toString(queryState));

  return await authServicesEndpoint
    .url(PATHNAMES.queryState)
    .put({ queryState: formattedQueryString.toString() })
    .json();
}
